const AWS           = require("aws-sdk");
const global_config = require('../config');
const uniqid        = require('uniqid');


class DynamoAgent {
    
    constructor (env)
    {
        // Configuration
        const config = global_config[env];
        
        // Initialize the Amazon Cognito credentials provider
        AWS.config.region = config.region; // Region
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.dynamo.IdentityPoolId,
        });

        this.client = new AWS.DynamoDB.DocumentClient();
        this.dataTableName = config.dynamo.dataTableName;
        this.userTableName = config.dynamo.userTableName;
    }

    async insertGameData (user, game, results)
    {
        /* 
        Example
        {
            TableName: "MathGameDB",
            Item: {
                "inputId":"<timestamp_userid>",
                "userName": "Vec",
                "userId": "12345",
                "gameId":"11",
                "gameName":"Non-symbolic magnitude comparison",
                "leftElements": "4,1,2,3,4,5",
                "rightElements": "3,2,1,1,3,2",
                "range": "5",
                "responses": "R,L,L,R,L,L",
                "answers": "False, True, True, False, True, True",
                "times": "0.132, 0.132, 0.132, 0.132, 0.132, 0.132",
                "timestamp": "<timestamp>",
                "congruent": "congruent, incongruent, incongruent, congruent, congruent, incongruent"
            }
        }
        */
        const jsonBody =  {
            TableName: this.dataTableName,
            Item: {
               "inputId": Date.now().toString() + '_' + user.id,
               "username": user.name,
               "userid": user.id,
               "gameId": game.id,
               "gameName": game.name,
               "leftElements": results.leftElems,
               "rightElements": results.rightElems,
               "centralElements": results.centralElems,
               "range": results.range,
               "responses": results.selection,
               "responseType": results.selectionMethod,
               "answers": results.answers,
               "times": results.reactionTime,
               "timestamp": (new Date(Date.now())).toISOString()
            }
        }

        console.log("Adding a new item...");
        this.client.put(jsonBody, function(err, data) {
            if (err) {
                console.error("Unable to add item. Error JSON:", err.code, err.message);
                console.log('Item was:', jsonBody);
                return false
            } else {
                console.log("Added item:", jsonBody);
                return true
            }
        });
    }

    async register (userdata, userType)
    {
        delete userdata.activeTab;
        delete userdata.submitted;
        let jsonBody =  {
            TableName: this.userTableName,
            Item: {
                "username": userdata.name.toLowerCase() + '.' + userdata.surname.toLowerCase(),
                "userid": uniqid('u-'),
                "type": userType
            }
        }
        // Save remaning info
        Object.keys(userdata).forEach((k) => {
            if (Object.keys(jsonBody.Item).indexOf(k) !== -1 || k === 'password' ) return;
            if (userdata[k] === '') return;
            jsonBody.Item[k] = userdata[k];
        });

        console.log("Adding a new user...");
        this.client.put(jsonBody, function(err, data) {
            if (err) {
                console.error("Unable to add user. Error JSON:", err.code, err.message);
                return false
            } else {
                console.log("Added user:", jsonBody);
                return true
            }
        });

    }
}

export default DynamoAgent;