import Phaser       from  'phaser';
import AbstractGame from './AbstractGame';

class BinaryGame extends AbstractGame {

    /**
     * Generic binary game with number selection with keys and mouse.
     * It keeps track of selections and reaction times and saves them to a Database.
     * @param {The custom key for the scene} name 
     */
    constructor (name)
    {
        super(name || 'BinaryGame');
        this.barSize = 2;
        this.samples = 25;
    }

    preload ()
    {
    }

    create ()
    {
        super.create();
        // Draw central bar
        const centralBar = this.add.graphics();
        centralBar.fillStyle('fff', 1);
        centralBar.fillRect(this.game.config.width/2, 0, 2*this.barSize, this.game.config.height);

        // Create texts for numbers
        const text_style = {
			fontSize: '200px',
			fontFamily: 'Arial',
			color: '#000000',
			wordWrap: { width: this.game.config.width/2 }
		};
        this.leftText = this.make.text(
            {x: this.game.config.width / 4, y: this.game.config.height / 2, 
            text: '', origin: { x: 0.5, y: 0.5 }, style: text_style}
        );
        this.rightText = this.make.text(
            {x: 3 * this.game.config.width / 4, y: this.game.config.height / 2,
            text: '', origin: { x: 0.5, y: 0.5 }, style: text_style}
        );

        this.next();
        // Key and mouse handlers for inputs
        this.input.on('pointerup', this.clickHandler, this);
        this.input.keyboard.on('keydown_CTRL', this.keyCtrl, this);
        this.input.keyboard.on('keydown_RIGHT', this.keyRight, this);
    }

    update ()
    {
        if (Date.now() - this.referenceTime > this.maxReactionTime) {
            this.noResponse();
        }
    }

    /**
     * Generate samples following a uniform distribution
     * between values in range.
     * @param {Array with lower and upper bounds for values} range 
     * @param {Integer with number of values to generate} samples 
     */
    generateSamplesUniformly (range, samples)
    {
        let aux = 0;
        let lastL = -1;
        let lastR = -1;
        for (let i = 0; i < samples; i++) {
            aux = Phaser.Math.Between(range[0], range[1]);
            while (aux === lastL) {
                aux = Phaser.Math.Between(range[0], range[1]);
            }
            lastL = aux;
            this.leftElems.push(aux);
            this.rightElems.push(aux);
            while (this.rightElems[i] === this.leftElems[i] || lastR === this.rightElems[i]) {
                this.rightElems[i] = Phaser.Math.Between(range[0], range[1]);
            }
            lastR = this.rightElems[i];
        }
    }

    /**
     * Generate balanced samples according to the relation of order
     * between units, tenth, and so on.
     * @param {Array with lower and upper bounds for values} range 
     * @param {Integer with number of values to generate} samples 
     */
    generateBalancedSamplesUnits (range, samples)
    {
        let aux = -1;
        let lastL = -1;
        let lastR = -1;
        let condaux = 0;
        let auxdigits = '';
        // Legend: 0 (greater), 1 (equal), 2 (less).
        // In the case of two digits we have 
        // four unique possibilities ['00', '01', '02', '10'].
        const possib = [];
        // Determine the number of digits to use
        const numDigits = range[1].toString().length;
        const digitSamples = new Array(numDigits); // Sampled numbers on conditions

        // Iterate to generate all possible cases, i.e. (3**numDigits - 1)/2.
        let newCase = '';
        let auxi = 0;
        for (let i = 0; i < (Math.pow(3, numDigits) - 1)/2; i++) {
            // New case will be '(i/(3*3))%3' + '(i/3)%3' + 'i%3' in the case of three digits.
            // Other cases are similar by iterating further.
            newCase = `${i%3}`;
            auxi = i;
            for (let j = 0; j < numDigits - 1; j++) {
                auxi = Math.floor(auxi/3);
                newCase = `${auxi%3}` + newCase;
            }
            possib.push(newCase);
        }

        // Generate an array with repetitions of unique cases as many as needed.
        let cases = possib;
        const numElems = samples/possib.length;
        for (let i = 0; i < numElems - 1; i++) {
            cases = cases.concat(possib);
        }
        // Shuffle array to alter order of appearance randomly.
        this.shuffleArray(cases);

        let cs = '';
        // const maxDec = Math.floor(range[1]/10);
        const maxDigits = range[1].toString();
        for (let i = 0; i < samples; i++) {
            // Set conditional sample to zero, since we are summing values iteratively afterwards.
            condaux = 0;
            // Conditional case.
            cs = cases[i];
            // Initialize array to -1
            for (let k = 0; k < numDigits; k++) digitSamples[k] = -1;
            // Sample new numbers until the conditional sample is possible.
            // We can find the number 999 and be looking for a greater number.
            while (digitSamples.indexOf(-1) > -1) {
                // Sort a new number
                aux = lastL;
                while (aux === lastL || aux === lastR) { // Ensure the new random number is not repeated
                    aux = Phaser.Math.Between(range[0], range[1]);
                }
                // console.log('Aux number', aux);
                auxdigits = aux.toString();
                // Compute a number for each of the digits in aux given a condition
                let a = ''
                for (let j = 0; j < numDigits; j++) {
                    // Sample numbers based on given condition: ['0', '1', '2']
                    a = this.conditionalSample(parseInt(auxdigits[j]), parseInt(maxDigits[j]), cs[j]);
                    digitSamples[j] = a;
                }
                // console.log('index', i, 'condition', cs);
                // console.log('index', i, 'digit samples', digitSamples);
            }

            let ind = 0;
            // TODO: Check that "no-loop-func" warning is not an issue here
            // eslint-disable-next-line
            digitSamples.forEach((el, k) => {
                ind = numDigits-k-1;
                condaux += el*Math.pow(10, ind);
            });

            // Alternate left and right sides to balance in which side is the greater number found.
            if (Phaser.Math.Between(0,1)) {
                this.leftElems.push(condaux);
                lastL = condaux;
                this.rightElems.push(aux);
                lastR = aux;
            } else {
                this.leftElems.push(aux);
                lastL = aux;
                this.rightElems.push(condaux);
                lastR = condaux;
            }
        }
        console.log('Samples left', this.leftElems);
        console.log('Samples right', this.rightElems);
    }

    /**
     * Randomize array element order in-place.
     * Using Durstenfeld shuffle algorithm.
     */
    shuffleArray(array)
    {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    /**
     * Sample a number based on three cases: greater (0), equal (1) or less (2)
     * in the given range if possible.
     * @param {integer} initialValue Minimum value in the sample domain. 
     * @param {integer} maxValue Maximum value in the sample domain.
     * @param {string} sampleCase One of the three possible cases: 0,1,2.
     */
    conditionalSample(initialValue, maxValue, sampleCase)
    {
        if (maxValue - initialValue <= 0) return -1;
        switch (sampleCase) {
            case '0': // greater
                return Phaser.Math.Between(initialValue + 1, maxValue);
            case '1': // equal
                return initialValue;
            case '2': // less
                return Phaser.Math.Between(0, initialValue - 1);
            default:
                console.error('Case not available:', sampleCase);
        }
    }

    clickHandler (pointer)
    {
        console.log('Pointer', pointer);
        this.reactionTime.push(Date.now() - this.referenceTime);
        this.selectionMethod.push('Mouse');
        if (pointer.upX < this.game.config.width / 2) {
            this.selection.push('L');
            this.isCorrect('L');
        } else {
            this.selection.push('R');
            this.isCorrect('R');
        }
        this.next();
    }

    noResponse ()
    {
        this.reactionTime.push(Date.now() - this.referenceTime);
        this.selectionMethod.push('None');
        this.selection.push('NR');
        this.isCorrect('NR');
        this.next();
    }

    keyCtrl (keyevent)
    {
        if (keyevent.code === 'ControlRight') return;
        this.reactionTime.push(Date.now() - this.referenceTime);
        this.selectionMethod.push('Keyboard');
        this.selection.push('L');
        this.isCorrect('L');
        this.next();
    }

    keyRight (keyevent)
    {
        this.reactionTime.push(Date.now() - this.referenceTime);
        this.selectionMethod.push('Keyboard');
        this.selection.push('R');
        this.isCorrect('R');
        this.next();
    }

}

export default BinaryGame;
