import BinaryGame from './BinaryGame';

/**
 * SNARC: Compare numbers in a numerical line range.
 * Show differences between responses with left and right hands.
 */
class Snarc extends BinaryGame {

    constructor ()
    {
        super('Snarc');
        this.id = '02';
    }
}

export default Snarc;
