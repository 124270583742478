import Phaser      from 'phaser';
import AbstractGame from './AbstractGame';

class NumericalLine extends AbstractGame {

    /**
     * Generic binary game with number selection with keys and mouse.
     * It keeps track of selections and reaction times and saves them to a Database.
     * @param {The custom key for the scene} name 
     */
    constructor (name)
    {
        super(name || 'NumericalLine');
        this.id = '04'
        this.samples = 10;
    }

    init (data)
    {
        this.elems = [];
        this.denom = [];
        super.init(data);
        
        this.barWidth = 2*this.game.config.width/3;
        this.barHeight = 5;
        this.pointerPosition = null;
        // Unit of measurement for accuracy computation
        this.basicUnit = 0.1*this.barWidth
    }

    preload ()
    {
    }

    create ()
    {
        super.create();
        // Reference position
        this.centralTextPosition = this.game.config.height/5;
        this.barVerticalPosition = 2*this.game.config.height/3;
        this.midWidth = this.game.config.width/2;
        this.midHeight = this.game.config.height/2;

        // Draw central bar and additional images
        this.cross = this.add.image(2*this.game.config.width/5, 4*this.game.config.height/5, 'cross').setVisible(false).setInteractive();
        this.cross.name = 'cross';
        this.check = this.add.image(3*this.game.config.width/5, 4*this.game.config.height/5, 'checkmark').setVisible(false).setInteractive();
        this.check.name = 'checkmark';
        const bar = this.add.graphics();
        bar.fillStyle('fff', 1);
        // Central horizontal bar
        bar.fillRect(
            this.midWidth - this.barWidth/2,
            this.barVerticalPosition, 
            this.barWidth, 
            this.barHeight
        );
        // Left vertical bar
        bar.fillRect(
            this.midWidth - this.barWidth/2,
            this.barVerticalPosition - 20, this.barHeight, 40
        );
        // Right vertical bar
        bar.fillRect(
            this.midWidth + this.barWidth/2,
            this.barVerticalPosition - 20, this.barHeight, 40
        );
        // Selection bar
        this.selectionBar = this.add.graphics();
        this.selectionBar.fillStyle('fff', 1);
        this.selectionBar.setVisible(false);
        this.selectionBar.fillRect(
            this.midWidth,
            this.barVerticalPosition - 20, this.barHeight, 40
        );

        // Create texts for numbers
        let text_style = {
			fontSize: '100px',
			fontFamily: 'Arial',
			color: '#000000',
			wordWrap: { width: this.midWidth }
		};
        this.centerText = this.make.text(
            {x: this.game.config.width / 2, y: this.centralTextPosition, 
            text: '', origin: { x: 0.5, y: 0.5 }, style: text_style}
        );
        text_style.fontSize = '50px';
        this.leftText = this.make.text(
            {x: this.game.config.width / 2 - this.barWidth/2 - 60, y: this.barVerticalPosition, 
            text: '', origin: 0.5, style: text_style}
        );
        this.rightText = this.make.text(
            {x: this.game.config.width / 2 + this.barWidth/2 + 60, y: this.barVerticalPosition,
            text: '', origin: 0.5, style: text_style}
        );

        this.next();
        // Key and mouse handlers for inputs
        this.input.on('pointermove', this.clickHandler, this);
        this.input.on('pointerdown', this.clickHandler, this);
        this.input.on('gameobjectdown', this.clickButton, this);
    }

    generateSamples (range, samples)
    {
        this.range = range;
        let aux = 0;
        let aux2 = 0;
        let last = 0;
        for (let i = 0; i < samples; i++) {
            // Avoid repeating same number
            while (aux === last) {
                if (this.taskConfig.type === 'integer') {
                    // Avoid boundaries
                    aux = Phaser.Math.Between(range[0]+1, range[1]-1);
                } else if (this.taskConfig.type === 'rational') {
                    // Denominator
                    aux2 = Phaser.Math.Between(2, 5);
                    // Numerator
                    aux = Phaser.Math.Between(1, range[1]*aux2-1);
                    aux = aux/aux2;
                } else {
                    console.log('Raise an error here.');
                }
            }
            last = aux;
            this.elems.push(aux);
            if (this.taskConfig.type === 'rational') {
                this.denom.push(aux2);
            }
            this.leftElems.push(range[0]);
            this.rightElems.push(range[1]);
        }
    }

    clickHandler (pointer)
    {
        if (pointer.isDown) {
            if (pointer.position.x <= this.game.config.width/2 + this.barWidth/2 && pointer.position.x >= this.game.config.width/2 - this.barWidth/2) {
                if (pointer.position.y <= this.barVerticalPosition + 20 && pointer.position.y >= this.barVerticalPosition - 20) {        
                    this.pointerPosition = {x: pointer.position.x, y: pointer.position.y};
                    this.selectionBar.clear();
                    this.selectionBar.fillRect(
                        pointer.position.x,
                        this.barVerticalPosition - 20, 2*this.barHeight/3, 40
                    );
                    this.selectionBar.setVisible(true);
                    this.cross.setVisible(true);
                    this.check.setVisible(true);
                }
            }
        }
    }

    clickButton (pointer, button)
    {
        if (button.name === 'checkmark') {
            this.reactionTime.push(Date.now() - this.referenceTime);
            this.selectionMethod.push('Mouse');
            // Pointer position to float point on the numerical line
            const selec = (this.range[1] - this.range[0])*(this.pointerPosition.x + this.barWidth/2 - this.midWidth)/(10*this.basicUnit);
            this.selection.push(selec);
            this.isCorrect(this.pointerPosition);
            this.next();
        }
        this.selectionBar.setVisible(false);
        this.cross.setVisible(false);
        this.check.setVisible(false);
    }

    isCorrect (pointerPosition)
    {
        const truePosition = 10*this.basicUnit*this.elems[this.currentState-1]/(this.range[1] - this.range[0]);
        const selectedPosition = pointerPosition.x + this.barWidth/2 - this.midWidth;
        const answer = Phaser.Math.Difference(truePosition, selectedPosition) <= this.basicUnit;
        // Temporal solution to example block of one stimulus at the beginning
        this.answers.push(answer);
        if (this.samples === 1) this.answers[0] = true;
    }

    next()
    {
        if (this.currentState < this.rightElems.length) {
            if (this.taskConfig.type === 'integer') {
                this.centerText.setText(this.elems[this.currentState].toString());
                this.centralElems.push(this.elems[this.currentState]);
            } else if (this.taskConfig.type === 'rational') {
                const num = this.elems[this.currentState]*this.denom[this.currentState]
                const text = num + '/' + this.denom[this.currentState];
                this.centerText.setText(text);
                this.centralElems.push(num/this.denom[this.currentState]);
            } else {
                console.log('Raise an error here.');
            }
        }
        super.next();
    }

}

export default NumericalLine;
