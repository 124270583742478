import React, { Component } from 'react';
import { 
  Col, 
  Row, 
  Form, 
  FormGroup, 
  Label, 
  Input } from 'reactstrap';
import './Form.css';

export default class ProfFormulary extends Component {

  constructor(props) {
    super(props);
  
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.props.onPropChange({
      [name]: value
    });
  }

  render() {
    return (
      <Form className="Form-block">

        <Label for="userInfo">Datos de usuario</Label>
        <Row className="Form-row" id="userInfo">
          <FormGroup row className="mr-sm-1 mb-sm-1">
            <Label for="profFormUsername" hidden>Nombre</Label>
            <Col>
              <Input type="text" name="name" id="profFormUsername" placeholder="Nombre" onChange={this.handleChange} />
            </Col>
          </FormGroup>
          <FormGroup row className="mr-sm-1 mb-sm-1">
            <Label for="profFormUsersurname" hidden>Apellido</Label>
            <Col>
              <Input type="text" name="surname" id="profFormUsersurname" placeholder="Apellido" onChange={this.handleChange} />
            </Col>
          </FormGroup>
        </Row>
        <Row className="Form-row">
          <FormGroup row className="mr-sm-1 mb-sm-1">
            <Label for="profFormEmail" hidden>Email</Label>
            <Col>
              <Input type="email" name="email" id="profFormEmail" placeholder="Email" onChange={this.handleChange} />
            </Col>
          </FormGroup>
          <FormGroup row className="mr-sm-1 mb-sm-1">
            <Label for="profFormPassword" hidden>Contraseña</Label>
            <Col>
              <Input type="password" name="password" id="profFormPassword" placeholder="Contraseña" onChange={this.handleChange} />
            </Col>
          </FormGroup>
        </Row>

        <Label for="profFormCenter">Centro</Label>
        <FormGroup row>
          <Col>
            <Input type="textarea" name="center" id="profFormCenter" onChange={this.handleChange} />
          </Col>
        </FormGroup>
      
      </Form>
    );
  }
}