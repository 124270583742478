import Phaser from 'phaser';
import { setButtonFrame, makeButton } from '../Buttons.js';

class MainMenu extends Phaser.Scene {

    constructor ()
    {
		super('MainMenu');
		this.name = 'MainMenu'
	}
	
	init (data)
	{
		this.parentScene = data.parent;
	}

	create ()
	{
		const bg = this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'background');
		bg.setDisplaySize(this.game.config.width, this.game.config.height);

		// Buttons
		const totalTasks = 5;
		const nRows = 3;
		const nCols = 3;
		const firstButtPos = [this.game.config.width / 4, this.game.config.height / 3];
		const step = [this.game.config.width / 4, this.game.config.height / 6];
		const tasks_buttons = [];
		for (let i = 0; i < totalTasks; i++) {
			var task_button = makeButton(this, 
										'Tarea ' + (i+1).toString(), 
										firstButtPos[0] + (i%nCols) * step[0], 
										firstButtPos[1] + Math.floor(i/nRows) * step[1], 
										i
										);
			tasks_buttons.push(task_button);
		}

		tasks_buttons.forEach(tb => {
			tb.on('pointerover', function ()
			{
				setButtonFrame(tb, 4);
			});
			tb.on('pointerout', function ()
			{
				setButtonFrame(tb, 2);
			});
			tb.on('pointerup', function ()
			{
				switch (tb.id) {
					case 0:
						this.scene.launch('Instructions', { nextScene: 'Snarc' });
						this.scene.setActive(false, this.name);
						break;
					case 1:
						this.scene.launch('Instructions', { nextScene: 'Stroop' });
						this.scene.setActive(false, this.name);
						break;
					case 2:
						this.scene.launch('Instructions', { nextScene: 'BallGame' });
						this.scene.setActive(false, this.name);
						break;
					case 3:
						this.scene.launch('Instructions', { nextScene: 'BallNumbers' });
						this.scene.setActive(false, this.name);
						break;
					case 4:
						this.scene.launch('Instructions', { nextScene: 'NumericalLine' });
						this.scene.setActive(false, this.name);
						break;
					default:
						//fx.play(button.name);
				}
			}, this);
			tb.on('gameobjectdown', function ()
			{
				setButtonFrame(tb, 2);
			});
		});

		// Fullscreen button
		var fsbutton = this.add.image(this.game.config.width-8, 8, 'fullscreen', 93).setOrigin(1, 0).setInteractive();

		fsbutton.on('pointerup', function () {

				if (this.scale.isFullscreen)
				{
						fsbutton.setFrame(93);
						
						// this.game.cameras.resize(this.game.config.width, this.game.config.height);
						this.scale.stopFullscreen();
				}
				else
				{
						fsbutton.setFrame(111);

						this.scale.startFullscreen();
				}

		}, this);

		var FKey = this.input.keyboard.addKey('F');

		FKey.on('down', function () {

				if (this.scale.isFullscreen)
				{
						fsbutton.setFrame(93);
						this.scale.stopFullscreen();
				}
				else
				{
						fsbutton.setFrame(111);
						this.scale.startFullscreen();
				}

		}, this);

        const countdownScene = this.scene.get('Countdown');
        this.scene.setVisible(false, countdownScene);
	}
};

export default MainMenu;
