export function setButtonFrame(button, frame)
{
    button.frame = button.scene.textures.getFrame('button', frame);
    // button.frame = thisgame.add.sprite(button.x, button.y)
}

export function makeButton(thisgame, name, x, y, id)
{
    const button = thisgame.add.image(x, y, 'button', 1).setInteractive();
    button.name = name;
    button.id = id;
    setButtonFrame(button, 2);
    const text_style = {
        fontSize: '24px',
        fontFamily: 'Arial',
        color: '#000000',
        wordWrap: { width: thisgame.game.config.width }
    };
    thisgame.make.text({x, y, text: name, origin: { x: 0.5, y: 0.5 }, style: text_style});

    return button;
}
