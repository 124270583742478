import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { Switch, Route } from 'react-router';
import NavigationBar from './Navbar';
import Main          from './Main';
import SignIn        from './SignIn';
import Formulary     from './Form';
import PhaserGame    from '../game/PhaserGame';
import CognitoAgent  from '../utils/cognito';

import './App.css';

/*
TODO
Visit https://fb.me/react-error-boundaries to learn more about error boundaries.
*/

class App extends Component {

  constructor (props) {
    super(props);

    this.state = {
      alert: false,
      message: '',
      isSignedUp: true,
      isConfirmed: false,
      isAuthenticated: false,
      // isAuthenticated: true,
      username: '',
      env: 'master'
    };
    this.authenticateUser = this.authenticateUser.bind(this);
    this.registerNewUser  = this.registerNewUser.bind(this);
    this.logOut           = this.logOut.bind(this);
    this.showAlert        = this.showAlert.bind(this);
    this.handleDismiss    = this.handleDismiss.bind(this);
    this.cognitoAgent     = new CognitoAgent(this.state.env);

    this.MainPage = (props) => {
      return (
        <div>
          {!this.state.isAuthenticated
          ?
          <div>
            <Main />
            <main>
              <SignIn
                isSignedUp={this.state.isSignedUp}
                isConfirmed={this.state.isConfirmed}
                isAuthenticated={this.state.isAuthenticated}
                authenticateUser={this.authenticateUser}
                env={this.state.env}
              />
            </main>
            {this.state.alert ? this.showAlert() : null}
          </div>
          :
          <PhaserGame username={this.state.username} env={this.state.env} />
          }
        </div>
      );
    }

    this.RegisterPage = (props) => {
      return (
        <div>
          <Formulary
            registerNewUser={this.registerNewUser}
          />
          {this.state.alert ? this.showAlert() : null}
        </div>
      );
    }
  }

  authenticateUser (username, password) {
    this.setState({username: username});
    this.setState({isAuthenticated: true}); // PROD: Delete this line and uncomment next one
    // this.cognitoAgent.authenticateUser(username, password, this);
  }

  registerNewUser (name, surname, email, password) {
    this.showAlert() // PROD: Delete this line and uncomment following ones
    // const username = this.cognitoAgent.registerUser(name, surname, email, password, this);
    // this.setState({username: username});
  }

  logOut () {
    this.setState({isAuthenticated: false});
  }

  handleDismiss () {
    this.setState({alert: false});
  }

  showAlert() {
    return (
      <Alert bsStyle="danger" onDismiss={this.handleDismiss}>
        <h4>¡No disponible!</h4>
        <p>
          {this.state.message}
        </p>
      </Alert>
    );
  }

  render() {
    return (
      <div>
        <NavigationBar isAuthenticated={this.state.isAuthenticated} logOut={this.logOut} />
          <Switch>
            <Route exact path='/'           component={this.MainPage}/>
            <Route exact path='/index.html' component={this.MainPage}/>
            <Route exact path='/register'   component={this.RegisterPage}/>
            <Route exact path='/info'       component={this.MainPage}/>
            <Route exact path='/contact'    component={this.MainPage}/>
          </Switch>
      </div>
    );
  }
}

export default App;
