import React, { Component } from 'react';
import { Redirect }         from 'react-router';
import {
    Col, 
    Row, 
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Button } from 'reactstrap';
import CognitoAgent from '../utils/cognito';
import './Form.css';

export default class SignIn extends Component {

  constructor(props) {
    super(props);
  
    this.state = {
        username: '',
        password: 'a' // PROD delete the 'a'
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cognitoAgent = new CognitoAgent(this.props.env);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    if (this.state.username.length > 0 && this.state.password.length > 0) {
      this.props.authenticateUser(this.state.username, this.state.password);
    }
  }

  render() {
    if (!this.props.isAuthenticated) {
      const isEnabled = this.state.username.length > 0 && this.state.password.length > 0;
      return (
        <div className="Form">
          <Form onSubmit={this.handleSubmit} className="Form-block">

              <Row className="Form-row" id="userInfo">
              <FormGroup row className="ml-sm-1">
                  <Label for="username" hidden>Usuario</Label>
                  <Col>
                  <Input type="text" name="username" id="username" placeholder="Usuario" onChange={this.handleChange} />
                  </Col>
              </FormGroup>
              </Row>
              {/* <Row className="Form-row">   PROD: uncomment these lines
              <FormGroup row className="ml-sm-1">
                  <Label for="password" hidden>Contraseña</Label>
                  <Col>
                  <Input type="password" name="password" id="password" placeholder="Contraseña" onChange={this.handleChange} />
                  </Col>
              </FormGroup>
              </Row> */}

              <FormGroup check row>
              <Col className="Button-center">
                  <Button onClick={this.handleSubmit} disabled={!isEnabled}>
                  Iniciar sesión
                  </Button>
              </Col>
              </FormGroup>
          
          </Form>
        </div>
      );
    } else {
      return(
        <Redirect to='/'/>
      );
    }
  }
}