import React from 'react';
import { Link } from 'react-router-dom'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink } from 'reactstrap';
import './Navbar.css'

export default class NavigationBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div>
        <Navbar className="Navbar" light expand="md">
          <h1>
            <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
            Additae
            </Link>
          </h1>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="Navbar-subtitle ml-auto" navbar>
              <NavItem>
                <Link to="/info/" style={{ textDecoration: 'none', color: 'white' }}>
                Información
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/contact/" style={{ textDecoration: 'none', color: 'white' }}>
                Contacto
                </Link>
              </NavItem>
              {!this.props.isAuthenticated
                ? 
                <NavItem>
                  <Link to="/register/" style={{ textDecoration: 'none', color: 'white' }}>
                  Registrarse
                  </Link>
                </NavItem>
                :
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav style={{ textDecoration: 'none', color: 'white' }}>
                    Mi cuenta
                  </DropdownToggle>
                  <DropdownMenu right>
                      <Link to='/config/' style={{ textDecoration: 'none', color: 'black' }}>
                      Configuración
                      </Link>
                      <Link to='/' style={{ textDecoration: 'none', color: 'black' }}>
                      Pasar test
                      </Link>
                  <DropdownItem divider />
                      <Link to='/' style={{ textDecoration: 'none', color: 'black' }} onClick={this.props.logOut}>
                      Salir
                      </Link>
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
              <NavItem>
                <NavLink style={{ textDecoration: 'none', color: 'gray' }} disabled href="#">v2.1.1</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}