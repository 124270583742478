import Phaser from  'phaser';

class Boot extends Phaser.Scene {

    constructor ()
    {
        super({ key: 'Boot', active: true });
    }

    preload ()
    {
        // this.load.image('loadingBar', '../../../src/assets/loadingBar.png');

        // const progressBar = this.add.graphics();
        // const progressBox = this.add.graphics();
        // progressBox.fillStyle(0x222222, 0.8);
        // progressBox.fillRect(240, 270, 320, 50);
        // this.load.on('progress', function (value) {
        //     progressBar.clear();
        //     progressBar.fillStyle(0xffffff, 1);
        //     progressBar.fillRect(250, 280, 300 * value, 30);
        // });
    }

    create ()
    {
        this.scene.launch('Preloader');
    }
}

export default Boot;
