import React 			from 'react';
import Phaser 			from 'phaser';
import createReactClass from 'create-react-class';
import { Alert } 		from 'react-bootstrap';
import './PhaserGame.css';
import Boot 		 from './scenes/Boot';
import Preloader 	 from './scenes/Preloader';
import MainMenu 	 from './scenes/MainMenu';
import Instructions  from './scenes/Instructions';
import Countdown     from './scenes/Countdown';
import BinaryGame 	 from './scenes/tasks/BinaryGame';
import Snarc 		 from './scenes/tasks/Snarc';
import BallGame 	 from './scenes/tasks/BallGame';
import BallNumbers 	 from './scenes/tasks/BallNumbers';
import Stroop 	     from './scenes/tasks/Stroop';
import NumericalLine from './scenes/tasks/NumericalLine';
// import UserForm 		 from './scenes/UserForm';

const CAN_WIDTH = 800;
const CAN_HEIGHT = 600;
const ratio = CAN_WIDTH/CAN_HEIGHT;
let HEIGHT = window.innerHeight;
HEIGHT = (1 - 0.1)*HEIGHT // Reduce height by 10% to avoid sliding
let WIDTH = window.innerWidth;
// Compute width and height to respect the game ratio
HEIGHT = Math.min(HEIGHT, WIDTH/ratio);
WIDTH = Math.min(WIDTH, HEIGHT*ratio);

var PhaserContainer = createReactClass({
	game: null,

	componentDidMount: function() {
		const config = {
			renderer: Phaser.AUTO,
			scale: {
				parent: 'phaser-container',
				mode: Phaser.Scale.FIT,
				width: WIDTH,
				height: HEIGHT
			},
			scene: [Boot, Preloader, MainMenu, Countdown, Instructions, 
		  			BinaryGame, Snarc, Stroop, BallGame, BallNumbers,
					NumericalLine]
		};
		this.game = new Phaser.Game(config);
		this.game.username = this.props.username || 'testuser';
		this.game.env = this.props.env || 'devel';
	},
	componentWillReceiveProps: function(nextProps) {
		this.useMask = nextProps.useMask;
	},
	shouldComponentUpdate: function(nextProps, nextState) {
		return false;
	},
	render: function() {
		return (
			<div className="phaserContainer" id="phaser-container">
			</div>
		);
	}
});

var PhaserGame = createReactClass({

  getInitialState: function(){
		return { hp: 100, atk: 2, item: null, level: 1, nextExp: 100, floor: 1, alertVisible: false, status: "win",
					 useMask: true};
  },
  handleGameEnd: function(isWin){
		if (isWin) {
			this.setState({ alertVisible: true, status: "win" });
		}
		else {
			this.setState({ alertVisible: true, status: "lose" });
		}
	},
  render: function(){
		var alert, alertclass;
		var message;
		
		if (this.state.status === "win") {
			message = "Congratulations!";
			alertclass = "success";
		}
		else {
			message = "You are defeated! Try Again!";
			alertclass = "danger";
		}
		
		if (this.state.alertVisible)
			alert = <Alert bsStyle={alertclass} onDismiss={this.handleAlertDismiss}><strong>{message}</strong></Alert>;
		else
			alert = "";
		
		return (
			<div className="container">
				{alert}
				<PhaserContainer username={this.props.username} env={this.props.env}/>
			</div>
		);
  }
});

export default PhaserGame;
