module.exports = {
    master: {
        cognito: {
            userPoolId: 'eu-central-1_xJYURqPN7',
            userPoolClientId: '33unqi06do569er8cv56coavie',
            region: 'eu-central-1'
        },
        api: {
            invokeUrl: '' // e.g. https://rc7nyt4tql.execute-api.us-west-2.amazonaws.com/prod',
        },
        region: 'eu-central-1',
        dynamo: {
            IdentityPoolId: 'eu-central-1:e693d68c-bbec-4062-8fb6-b8f2212921fe',
            dataTableName: 'MathGameData',
            userTableName: 'MathGameUser'
        }
    },
    devel: {
        cognito: {
            userPoolId: 'eu-central-1_xJYURqPN7',
            userPoolClientId: '33unqi06do569er8cv56coavie',
            region: 'eu-central-1'
        },
        api: {
            invokeUrl: '' // e.g. https://rc7nyt4tql.execute-api.us-west-2.amazonaws.com/prod',
        },
        region: 'eu-central-1',
        dynamo: {
            IdentityPoolId: 'eu-central-1:e693d68c-bbec-4062-8fb6-b8f2212921fe',
            dataTableName: 'MathGameData_test',
            userTableName: 'MathGameUser_test'
        }
    }
};