import React, { Component } from 'react';
import { 
  Col, 
  Row, 
  Form, 
  FormGroup, 
  Label, 
  Input, 
  CustomInput } from 'reactstrap';
import './Form.css';

export default class NewFormulary extends Component {
  constructor(props) {
    super(props);
    this.state = {isToggleRepited: true};

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClick() {
    this.setState(prevState => ({
      isToggleRepited: !prevState.isToggleRepited
    }));
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.props.onPropChange({
      [name]: value
    });
  }

  render() {
    return (
      <Form className="Form-block">

        <Label for="userInfo">Datos de usuario</Label>
        <Row className="Form-row" id="userInfo">
          <FormGroup row className="mr-sm-1 mb-sm-1">
            <Label for="formUsername" hidden>Nombre</Label>
            <Col>
              <Input type="text" name="name" id="formUsername" placeholder="Nombre" onChange={this.handleChange} />
            </Col>
          </FormGroup>
          <FormGroup row className="mr-sm-1 mb-sm-1">
            <Label for="formUsersurname" hidden>Apellido</Label>
            <Col>
              <Input type="text" name="surname" id="formUsersurname" placeholder="Apellido" onChange={this.handleChange} />
            </Col>
          </FormGroup>
        </Row>
        <Row className="Form-row">
          <FormGroup row className="mr-sm-1 mb-sm-1">
            <Label for="formEmail" hidden>Email</Label>
            <Col>
              <Input type="email" name="email" id="formEmail" placeholder="Email" onChange={this.handleChange} />
            </Col>
          </FormGroup>
          <FormGroup row className="mr-sm-1 mb-sm-1">
            <Label for="formPassword" hidden>Contraseña</Label>
            <Col>
              <Input type="password" name="password" id="formPassword" placeholder="Contraseña" onChange={this.handleChange} />
            </Col>
          </FormGroup>
        </Row>

        <Label for="genderRadio">Información adicional</Label>
        <Row className="Form-row">
          <FormGroup row className="mr-sm-4 mb-sm-1">
            <div>
              <CustomInput type="radio" id="genderRadio" name="gender" label="Mujer" value="Mujer" inline onChange={this.handleChange} />
              <CustomInput type="radio" id="genderRadio2" name="gender" label="Hombre" value="Hombre" inline onChange={this.handleChange} />
            </div>
          </FormGroup>
          <FormGroup row className="ml-sm-3 mr-sm-1 mb-sm-1">
            <Col>
              <Input type="text" name="birthdate" id="formDate" placeholder="dd/mm/aaaa" onChange={this.handleChange} />
            </Col>
          </FormGroup>
        </Row>
        <Row className="Form-row">
          <FormGroup row className="mr-sm-4 mb-sm-1">
            <div>
              <CustomInput type="radio" id="handRadio" name="hand" label="Diestro" valor="Diestro" inline onChange={this.handleChange} />
              <CustomInput type="radio" id="handRadio2" name="hand" label="Zurdo" valor="Zurdo" inline onChange={this.handleChange} />
            </div>
          </FormGroup>
          <FormGroup row className="ml-sm-5 mb-sm-1">
              <CustomInput type="checkbox" id="repitedCourses" name="repited" label="Ha repetido" onClick={this.handleClick} inline />
              <Label for="numberOfRepitedCourses" hidden>Cursos repetidos</Label>
              <CustomInput type="select" id="numberOfRepitedCourses" name="repitedCourses" disabled={this.state.isToggleRepited} onChange={this.handleChange}>
                <option value="">Nº cursos</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
              </CustomInput>
          </FormGroup>
        </Row>

        <Label for="diagnostics">Diagnósticos</Label>
        <Row className="Form-row">
          <FormGroup row className="mb-sm-1">
            <div>
              <CustomInput type="checkbox" id="diagnostics1" name="others1" label="Dislexia" value="dyslexia" inline onChange={this.handleChange} />
              <CustomInput type="checkbox" id="diagnostics2" name="others2" label="Discalculia" value="dyscalculia" inline onChange={this.handleChange} />
              <CustomInput type="checkbox" id="diagnostics3" name="others3" label="TDAH" value="tdah" inline onChange={this.handleChange} />
              <CustomInput type="checkbox" id="diagnostics4" name="others4" label="Discapacidad cognitiva" value="cognitive" inline onChange={this.handleChange} />
              <CustomInput type="checkbox" id="diagnostics5" name="others5" label="Otra diversidad funcional" value="other" onChange={this.handleChange} />
            </div>
          </FormGroup>
        </Row>

        <Label for="observations">Observaciones</Label>
        <FormGroup row>
          <Col>
            <Input type="textarea" name="observations" id="observations" onChange={this.handleChange} />
          </Col>
        </FormGroup>

      </Form>
    );
  }
}