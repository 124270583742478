import React, { Component } from 'react';
import { Redirect }         from 'react-router';
import { TabContent, TabPane, Nav, NavItem, NavLink, FormGroup, Button, Col } from 'reactstrap';
import classnames    from 'classnames';
import NewFormulary  from './NewUserForm';
import ProfFormulary from './ProfessionalForm';
import DynamoAgent   from '../utils/dynamodb';
import CognitoAgent  from '../utils/cognito';

import './Form.css';

export default class Formulary extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '0', value: '',
      name: '', surname: '', email: '', password: '',
      center: '', type: '',
      gender: '', birthdate: '', hand: '',
      repited: '', repitedCourses: '0',
      others: [], observations: '',
      submitted: false
    };
    this.tabNames = ['patient', 'professional'];
    this.requiredFields = {
      patient: ['name', 'surname', 'email', 'password', 'gender', 'hand', 'birthdate'],
      professional: ['name', 'surname', 'email', 'password', 'center']
    };
    this.handleChange  = this.handleChange.bind(this);
    this.handleSubmit  = this.handleSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.dynamoAgent   = new DynamoAgent();
    this.cognitoAgent  = new CognitoAgent();
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  onInputChange(change) {
    this.setState(change);
    this.checkValidData();
  }

  checkValidData() {
    const tab = this.tabNames[Number(this.state.activeTab)];
    let k = '';
    for (let i in this.requiredFields[tab]) {
      k = this.requiredFields[tab][i];
      if (this.state[k] === '') return false;
    }
    return true;
  }

  handleSubmit = async event => {
    try {
      // PROD: Uncomment these lines
      // this.dynamoAgent.register(this.state, this.tabNames[Number(this.state.activeTab)]);
      // this.props.registerNewUser(this.state.name, this.state.surname, this.state.email, this.state.password);
      // this.setState({submitted: true});
    } catch (e) {
      console.error('Error in FORM', e);
    }
  }

  render() {
    const isEnabled = this.checkValidData();
    if (!this.state.submitted) {
      return (
        <div className="Form">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '0' })}
                onClick={() => { this.toggle('0'); }}
              >
                Paciente
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '1' })}
                onClick={() => { this.toggle('1'); }}
              >
                Profesional
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="0">
              <NewFormulary onPropChange={this.onInputChange} />
            </TabPane>
            <TabPane tabId="1">
              <ProfFormulary onPropChange={this.onInputChange} />
            </TabPane>
          </TabContent>
          <FormGroup check row>
            <Col className="Button-center">
              <Button onClick={this.handleSubmit} disabled={!isEnabled}>
                Solicitar acceso
              </Button>
            </Col>
          </FormGroup>
        </div>
      );
    } else {
      return (
        <Redirect to='/'/>
      );
    }
  }
}