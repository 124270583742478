import Phaser      from 'phaser';
import DynamoAgent from '../../../utils/dynamodb';

class AbstractGame extends Phaser.Scene {

    /**
     * Generic binary game with number selection with keys and mouse.
     * It keeps track of selections and reaction times and saves them to a Database.
     * @param {The custom key for the scene} name 
     */
    constructor (name)
    {
        super(name || 'AbstractGame');
        this.id = '00';
        this.name = name || 'AbstractGame';
    }
    
    init (data)
    {
        this.dynamoAgent = new DynamoAgent(this.game.env);
        this.level = data.level || 1;
        this.id = this.id + this.level.toString().padStart(2, '0');
        this.levels = this.cache.json.get('levels')[this.game.env][this.name];
        // If level does not exist, end the game.
        this.taskConfig = this.levels[this.level.toString().padStart(2, '0')];
        this.currentState = 0;
        this.leftElems = [];
        this.rightElems = [];
        this.centralElems = [];
        this.selection = [];
        this.selectionMethod = [];
        this.reactionTime = [];
        this.referenceTime = 0;
        this.maxReactionTime = 5e3;
        if (this.taskConfig.levelType==='demo') this.maxReactionTime *= 1e3;
        this.answers = [];
        this.accuracy = 0;

        this.samples = this.taskConfig.samples || this.samples;
        this.generateSamples(this.taskConfig.range, this.samples);
    }

    preload ()
    {
    }

    create ()
    {
        // Draw background
        this.add.rectangle(this.game.config.width/2, this.game.config.height/2, 
                           this.game.config.width, this.game.config.height, 0xe2e2e2)
    }

    update ()
    {
    }

    generateSamples (range, samples)
    {
        console.log(`Generating ${samples} samples for range ${range}`);
        if (this.taskConfig.balanceType === 'units') {
            this.generateBalancedSamplesUnits(range, samples);
        } else if (this.taskConfig.balanceType === 'ratios') {
            this.generateBalancedSamplesRatios(range, samples);
        } else {
            this.generateSamplesUniformly(range, samples);
        }
    }

    generateSamplesUniformly (range, samples)
    {
        // `Generating ${samples} balanced samples for range ${range}`
    }

    generateBalancedSamplesUnits (range, samples)
    {
        // `Generating ${samples} balanced samples for range ${range}`
    }

    generateBalancedSamplesRatios (range, samples)
    {
        // `Generating ${samples} balanced samples for range ${range}`
    }

    // Saves whether the user selection is correct or not
    isCorrect (side)
    {
        if (side === 'L') {
            this.answers.push(this.leftElems[this.currentState-1] - this.rightElems[this.currentState-1] > 0);
        } else if (side === 'R') {
            this.answers.push(this.rightElems[this.currentState-1] - this.leftElems[this.currentState-1] > 0);
        } else {
            this.answers.push(false);
        }
    }

    // Save information to database
    saveToDB ()
    {
        if (this.answers.length > 0) {
            const user = {id: this.game.username, name: 'NA'};
            const game = {id: this.id, name: this.name};
            const results = {
                leftElems: this.leftElems,
                rightElems: this.rightElems,
                centralElems: this.centralElems,
                range: this.taskConfig.range,
                selection: this.selection,
                selectionMethod: this.selectionMethod,
                reactionTime: this.reactionTime,
                answers: this.answers
            }
            this.dynamoAgent.insertGameData(user, game, results);
        }
    }

    next()
    {
        if (this.currentState >= this.rightElems.length) {
            this.end();
            return;
        }
        // Draw elements
        this.leftText.setText(this.leftElems[this.currentState].toString());
        this.rightText.setText(this.rightElems[this.currentState].toString());
        this.currentState += 1;
        this.referenceTime = Date.now();
    }

    end()
    {
        this.saveToDB();
        this.accuracy = this.answers.filter(Boolean).length/this.samples;
        console.log('acc:', this.accuracy);
        // TODO: Find a pretty way to ignora accuracy in demo levels
        if (this.taskConfig.levelType === 'demo') this.accuracy = 1;
        if (this.accuracy < 0.5) {
            this.scene.launch('Instructions', {nextScene: 'MainMenu', level: -1});
        } else {
            // Next level only if it exists
            if (Object.keys(this.levels).indexOf((this.level+1).toString().padStart(2, '0')) !== -1) {
                this.scene.launch('Instructions', {nextScene: this.name, level: this.level + 1});
            } else {
                this.scene.launch('Instructions', {nextScene: 'MainMenu', level: -1});
            }
        }
        this.scene.setActive(false, this.name);
        this.scene.setVisible(false, this.name);
        this.leftText.setText('');
        this.rightText.setText('');

        const countdownScene = this.scene.get('Countdown');
        this.scene.setVisible(false, countdownScene);
    }

}

export default AbstractGame;
