import Phaser from  'phaser';

class Instructions extends Phaser.Scene {

    constructor ()
    {
        super('Instructions');
        this.name = 'Instructions';
        this.level = 1;
    }

    init (data)
    {
        this.nextScene = data.nextScene;
        this.data = data;
        this.scene.bringToTop();
    }

    preload ()
    {
    }

    create ()
    {
        const center = [this.game.config.width/2, this.game.config.height/2];
        const width = 3*this.game.config.width/4;
        const height = 3*this.game.config.height/5;
        this.add.rectangle(center[0], center[1], width, height, 0xc1c1aa);
        // Use level information to extract instructions
        const INSTR  = this.cache.json.get('strings');
        let text_style = {
            fontSize: '32px', fontFamily: 'Arial',
			color: '#000000', wordWrap: { width: width - 20, height: height - 10 }
        };
        // Get level information
        this.level = this.data.level || this.level;
        // Print different texts according to level number
        if (this.level < 0) {
            this.make.text({
                x: center[0], y: center[1],
                text: INSTR['end_msg'], origin: {x: 0.5, y: 1}, style: text_style
            })
        } else {
            const levels = this.cache.json.get('levels')[this.game.env][this.nextScene];
            const taskConfig = levels[this.level.toString().padStart(2, '0')];
            const LVL_INSTR  = INSTR[this.nextScene][taskConfig.levelType];
            if (this.level <= 2) {
                this.make.text({
                    x: center[0], y: center[1] - height/2 + 10, 
                    text: LVL_INSTR['description'], origin: {x: 0.5, y: 0}, style: text_style
                });
                this.make.text({
                    x: center[0] - width/2 + 10, y: center[1] - height/2 + 60, 
                    text: LVL_INSTR['howto'], style: text_style
                });
                text_style.fontSize = '28px';
                this.make.text({
                    x: center[0] - width/2 + 30, y: center[1] - height/2 + 100, 
                    text: this.stringTemplateParser(LVL_INSTR['howto_msg'], taskConfig), style: text_style
                });
                    
            } else {
                this.make.text({x: center[0], y: center[1] - height/2 + 10, 
                    text: LVL_INSTR['description'], origin: {x: 0.5, y: 0}, style: text_style});
                text_style.fontSize = '28px';
                this.make.text({
                    x: center[0] - width/2 + 30, y: center[1] - height/2 + 100, 
                    text: INSTR['continue_msg'], style: text_style
                });
            }
        }

        this.make.text({
            x: center[0], y: center[1] + height/2 - 10, 
            text: INSTR['continue_action'], origin: {x: 0.5, y: 1}, style: text_style
        });

        // Continue button
        this.input.keyboard.on('keydown_SPACE', this.end, this);
    }

    stringTemplateParser (expression, valueObj)
    {
        const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
        let text = expression.replace(templateMatcher, (substring, value, index) => {
            // Check whether the expression has an array indexer
            let m = value.match(/\s*(\[\d*\])/g);
            if (m) {
                value = valueObj[value.replace(m, '')][m[0].slice(1,-1).toString()];
            } else {
                value = valueObj[value];
            }
            return value;
        });

        return text
    }
      

    end ()
    {
        if (this.level > 0) {
            this.scene.launch('Countdown', this.data);
        } else {
            this.data.totalCount = 0
            this.scene.launch('Countdown', this.data);
        }
        this.scene.setActive(false, this.name);
        this.scene.setVisible(false, this.name);
    }
}

export default Instructions;
