const AmazonCognitoIdentity = require("amazon-cognito-identity-js");
const AWS                   = require("aws-sdk");
const global_config         = require("../config");


class CognitoAgent {
    
    constructor (env) 
    {
        // Configuration
        const config = global_config[env];
        
        // Initialize the Amazon Cognito credentials provider
        AWS.config.region = config.region; // Region
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.dynamo.IdentityPoolId,
        });

        const poolData = {
            UserPoolId: config.cognito.userPoolId,
            ClientId: config.cognito.userPoolClientId
        };
        this.AWSCognito = new AWS.CognitoIdentity();
        this.AWSCognito.config.region = config.cognito.region;
        this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    }

    // Register new user
    registerUser (username, usersurname, email, password, _this) {
        let attributeList = [];
    
        let dataEmail = {
            Name : 'email',
            Value : email
        };
        let attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);

        attributeList.push(attributeEmail);

        const userKey = username.toLowerCase() + '.' + usersurname.toLowerCase();
        this.userPool.signUp(userKey, password, attributeList, null, function(err, result){
            // console.log('err', err);
            // console.log('result', result);
            if (err) {
                console.log('err', err.code, err.message);
                return;
            }
            _this.setState({isSignedUp: true});
            // let cognitoUser = result.user;
            // console.log('user name is ' + cognitoUser.getUsername());
        });

        return userKey;
    }

    createCognitoUser (email) {
        return this.userPool.CognitoUser({
            Username: email,
            Pool: this.userPool
        });
    }

    verify (email, code) {
        this.createCognitoUser(email).confirmRegistration(code, true, function confirmCallback(err, result) {
            if (!err) {
                console.log('Success', result);
            } else {
                console.log('Error código incorrecto', err.code, err.message);
            }
        });
    }

    authenticateUser (username, password, _this) {
        let userData = { 
            Username : username,
            Pool : this.userPool
        };

        let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

        let authenticationData = {
            Username : username,
            Password : password,
        };

        let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                console.log('Authentification successfull!');
                _this.setState({isAuthenticated: true});
            },

            onFailure: function(err) {
                console.log('Failure', err);
                if (err.code === 'NotAuthorizedException') {
                    _this.setState({message: 'Contraseña o usuario incorrectos.'})
                } else if (err.code === 'UserNotConfirmedException') {
                    _this.setState({message: 'Debes verificar aún el código que has recibido en tu correo electrónico.'})
                }
                _this.setState({alert: true})
            },

            mfaRequired: function(codeDeliveryDetails) {
                console.log('Failure', codeDeliveryDetails);
                let verificationCode = prompt('Please input verification code' ,'');
                cognitoUser.sendMFACode(verificationCode, this);
            }

        });
    }
}

export default CognitoAgent;