import Phaser     from 'phaser';
import BallGame from './BallGame';

class BallNumbers extends BallGame {

    constructor (name)
    {
        super(name || 'BallNumbers');
        this.id = '05';
    }

    init (data)
    {
        super.init(data);
        // This game appears always in pairs
        this.twinNumber = data.twinNumber || 1;
        this.previousAccuracy = data.previousAccuracy || 0.0;
    }

    preload ()
    {
        super.preload()
        delete this.grid.right;
    }

    create ()
    {
        // Draw background and central bar
        this.add.rectangle(this.game.config.width/2, this.game.config.height/2, 
                           this.game.config.width, this.game.config.height, 0xe2e2e2)
        const centralBar = this.add.graphics();
        centralBar.fillStyle('fff', 1);
        centralBar.fillRect(this.game.config.width/2, 0, 2*this.barSize, this.game.config.height);

        // Create texts for numbers
        const text_style = {
			fontSize: '200px',
			fontFamily: 'Arial',
			color: '#000000',
			wordWrap: { width: this.game.config.width/2 }
		};
        this.rightText = this.make.text(
            {x: 3 * this.game.config.width / 4, y: this.game.config.height / 2,
            text: '', origin: { x: 0.5, y: 0.5 }, style: text_style}
        );

        this.next();
        // Key and mouse handlers for inputs
        this.input.on('pointerup', this.clickHandler, this);
        this.input.keyboard.on('keydown_CTRL', this.keyCtrl, this);
        this.input.keyboard.on('keydown_RIGHT', this.keyRight, this);
    }

    next()
    {
        if (this.currentState >= this.rightElems.length) {
            this.end();
            return;
        }
        if (this.currentImages.length > 0) {
            this.currentImages.forEach((im) => {
                im.setVisible(false);
                im.destroy();
            });
        }
        // Draw elements
        let auxPos = 0;
        let elem = null;
        let img = null;
        // Select new grid for every iteration
        let auxGrid = JSON.parse(JSON.stringify(this.grid));
        for (let i = 0; i < this.leftElems[this.currentState]; i++) {
            auxPos = Phaser.Math.Between(0, auxGrid.left.length - 1);
            elem = auxGrid.left[auxPos];
            img = this.add.image(elem[0], elem[1], 'smallball');
            this.currentImages.push(img);
            auxGrid.left.splice(auxPos, 1); // Delete one element
        }
        // Draw right number
        this.rightText.setText(this.rightElems[this.currentState].toString());
        this.currentState += 1;
        this.referenceTime = Date.now();
    }

    end()
    {
        this.saveToDB();
        this.accuracy = this.answers.filter(Boolean).length/this.samples;
        if (this.taskConfig.levelType === 'demo') {
            this.accuracy = 1;
            this.twinNumber = 0;
        }
        if (this.twinNumber === 1) {
            // Launch second part of this game if it exists
            if (Object.keys(this.levels).indexOf((this.level+1).toString().padStart(2, '0')) !== -1) {
                this.scene.launch('Instructions', 
                    {nextScene: this.name, level: this.level + 1,
                    previousAccuracy: this.accuracy, twinNumber: this.twinNumber + 1});
            } else {
                // TODO: Launch instructions with end message and MainMenu as next scene
                this.scene.launch('Instructions', {nextScene: 'MainMenu', level: -1});
            }
        } else {
            this.accuracy = 0.5*(this.accuracy + this.previousAccuracy);

            if (this.accuracy < 0.5) {
                // TODO: Launch instructions with end message and MainMenu as next scene
                this.scene.launch('Instructions', {nextScene: 'MainMenu', level: -1});
            } else {
                // Next level only if it exists
                if (Object.keys(this.levels).indexOf((this.level+1).toString().padStart(2, '0')) !== -1) {
                    this.scene.launch('Instructions', {nextScene: this.name, level: this.level + 1});
                } else {
                    // TODO: Launch instructions with end message and MainMenu as next scene
                    this.scene.launch('Instructions', {nextScene: 'MainMenu', level: -1});
                }
            }
        }

        this.scene.setActive(false, this.name);
        this.scene.setVisible(false, this.name);
        
        const countdownScene = this.scene.get('Countdown');
        this.scene.setVisible(false, countdownScene);
    }
}

export default BallNumbers;
