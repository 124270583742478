import Phaser from  'phaser';

class Countdown extends Phaser.Scene {

    constructor ()
    {
        super('Countdown');
        this.name = 'Countdown';
    }

    init (data)
    {
        this.nextScene = data.nextScene;
        this.data = data;
        this.startTime = Date.now();
        this.totalCount = 3;
    }

    preload ()
    {
    }

    update ()
    {
        if (this.totalCount > (Date.now() - this.startTime)/1e3 ) {
            this.centerText.text = (Math.floor(this.totalCount - (Date.now() - this.startTime)/1e3) + 1).toString();
        } else {
            this.end()
        }

    }

    create ()
    {
        if (this.data.totalCount !== undefined) this.totalCount = this.data.totalCount;
        this.add.rectangle(
            this.game.config.width/2, this.game.config.height/2, 
            this.game.config.width, this.game.config.height, 0xe2e2e2
        );
        // Create texts for numbers
        let text_style = {
			fontSize: '100px',
			fontFamily: 'Arial',
			color: '#000000',
			wordWrap: { width: this.midWidth }
		};
        this.centerText = this.make.text(
            {x: this.game.config.width / 2, y: this.game.config.height / 2, 
            text: '', origin: { x: 0.5, y: 0.5 }, style: text_style}
        );

        this.centerText.text = this.totalCount.toString();
    }

    end ()
    {
        this.scene.launch(this.nextScene, this.data);
        this.scene.setActive(false, this.name);
    }
}

export default Countdown;
