import Phaser from 'phaser';

class Preloader extends Phaser.Scene {

    constructor ()
    {
        super({ key: 'Preloader' });
    }

    preload ()
    {
		// LOAD ALL ASSETS
		this.load.spritesheet('button', '../src/assets/ui/greySheet.png', { frameWidth: 190, frameHeight: 48});
        this.load.image('background', '../src/assets/background.png');
        this.load.image('ball', '../src/assets/blue_circle_small.png');
        this.load.image('smallball', '../src/assets/blue_circle_tiny.png');
        this.load.image('checkmark', '../src/assets/checkmark.png');
        this.load.image('cross', '../src/assets/cross.png');
        this.load.json('strings', '../src/assets/strings.json');
        this.load.json('levels', '../src/assets/levels.json');
        this.load.spritesheet('fullscreen', '../src/assets/ui/sheet_white2x.png', { frameWidth: 100, frameHeight: 100 });
    }

    create ()
    {
        // this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'background');
        // this.scene.launch('UserForm');
        this.scene.launch('MainMenu');
    }
};

export default Preloader;
