import Phaser     from 'phaser';
import BinaryGame from './BinaryGame';

class BallGame extends BinaryGame {

    constructor (name)
    {
        super(name || 'BallGame');
        this.name = name || 'BallGame';
        this.id = '01';
        this.barSize = 2;
        this.samples = 25;
    }

    init (data)
    {
        super.init(data);
        this.grid = {left: [], right: []};
        this.currentImages = [];
    }

    preload ()
    {
        // Set basic ball size
        let image = this.add.image(0, 0, 'smallball').setVisible(false);
        // let simage = this.add.image(0, 0, 'smallball').setVisible(false);
        const rect = image.getBounds();
        this.refSize = rect.width;
        image.destroy();
        image = null;

        // Create grid of possible locations for objects
        const minMargin = Math.floor(this.refSize/2) + 0.02*this.game.config.width; // minimum margin at boundaries
        // Compute left space when fitting objects with a minimum margin
        const vSpaceLeft = Math.floor(this.game.config.height - Math.floor((this.game.config.height - 2*minMargin)/this.refSize)*this.refSize);
        const hSpaceLeft = Math.floor(0.5*this.game.config.width - Math.floor((0.5*this.game.config.width - 2*minMargin)/this.refSize)*this.refSize);
        // Set number of rows and columns by number of balls that fit the remaining grid
        let nRows = Math.floor((0.5*this.game.config.width - 2*minMargin)/this.refSize);
        let nCols = Math.floor((this.game.config.height - 2*minMargin)/this.refSize);
        const vMargin = Math.floor(0.5*vSpaceLeft);
        const hMargin = Math.floor(0.5*hSpaceLeft);
        
        for (let r = 0; r < nRows; r++) {
            for (let c = 0; c < nCols; c++) {
                this.grid.left.push([hMargin + this.refSize * r, vMargin + this.refSize * c]);
                this.grid.right.push([this.game.config.width/2 + hMargin + this.refSize * r, vMargin + this.refSize * c]);
            }
        }
    }

    create ()
    {
        // Draw background and central bar
        this.add.rectangle(this.game.config.width/2, this.game.config.height/2, 
                           this.game.config.width, this.game.config.height, 0xe2e2e2)
        const centralBar = this.add.graphics();
        centralBar.fillStyle('fff', 1);
        centralBar.fillRect(this.game.config.width/2, 0, 2*this.barSize, this.game.config.height);

        this.next();
        // Key and mouse handlers for inputs
        this.input.on('pointerup', this.clickHandler, this);
        this.input.keyboard.on('keydown_CTRL', this.keyCtrl, this);
        this.input.keyboard.on('keydown_RIGHT', this.keyRight, this);
    }

    /**
     * Generate balanced samples according to the desired ratios
     * between maximum and minimum values shown.
     * @param {Array with lower and upper bounds for values} range 
     * @param {Integer with number of values to generate} samples 
     */
    generateBalancedSamplesRatios (range, samples)
    {
        let auxL = 0;
        let auxR = 0;
        let lastL = -1;
        let lastR = -1;
        let accepted = false;
        let ratio = 0;
        let posit = -1;
        let possibRatios = [];
        if (range[1] < 99) {
            possibRatios = [1.5, 2];
        } else {
            possibRatios = [1.1, 1.12, 1.15, 1.3, 1.5, 1.7, 2];
        }
        let ratios = [];
        // Number of elements per case.
        const numElems = samples/(possibRatios.length+1);
        for (let i = 0; i < samples; i++) {
            let cnt = -1;
            accepted = false;
            // Iterate until the new values match one of the
            // possible ratios still available.
            while (!accepted && cnt < 100) {
                cnt++;
                auxL = Phaser.Math.Between(range[0], range[1]);
                while (auxL === lastL) {
                    auxL = Phaser.Math.Between(range[0], range[1]);
                }
                auxR = auxL;
                while (auxR === auxL || lastR === auxR) {
                    auxR = Phaser.Math.Between(range[0], range[1]);
                }
                // Compute ratio and position of it in the array of
                // all possible ratios.
                ratio = Math.max(auxL, auxR)/Math.min(auxL, auxR);
                posit = possibRatios.length;
                // TODO: Check that "no-loop-func" warning is not an issue here
                // eslint-disable-next-line
                possibRatios.some((rt, idx) => {
                    if (ratio < rt) {
                        posit = idx;
                        return true;
                    }
                    return false;
                });
                // If there is room for given ratio, add it.
                // TODO: Check that "no-loop-func" warning is not an issue here
                // eslint-disable-next-line
                if (ratios.filter(x => x===posit).length < numElems) {
                    accepted = true;
                    ratios.push(posit);
                }
            }
            this.leftElems.push(auxL);
            this.rightElems.push(auxR);
            lastL = auxL;
            lastR = auxR;
        }
        let resRatios = [];
        this.rightElems.forEach((re, idx) => {
            resRatios.push(Math.max(re, this.leftElems[idx])/Math.min(re, this.leftElems[idx]));
        })
    }

    next()
    {
        if (this.currentState >= this.rightElems.length) {
            this.end();
            return;
        }
        if (this.currentImages.length > 0) {
            this.currentImages.forEach((im) => {
                im.setVisible(false);
                im.destroy();
            });
        }
        // Draw elements
        let auxPos = 0;
        let elem = null;
        let img = null;
        // Select new grid for every iteration
        let auxGrid = JSON.parse(JSON.stringify(this.grid));
        for (let i = 0; i < this.leftElems[this.currentState]; i++) {
            auxPos = Phaser.Math.Between(0, auxGrid.left.length - 1);
            elem = auxGrid.left[auxPos];
            img = this.add.image(elem[0], elem[1], 'smallball');
            this.currentImages.push(img);
            auxGrid.left.splice(auxPos, 1); // Delete one element
        }
        for (let i = 0; i < this.rightElems[this.currentState]; i++) {
            auxPos = Phaser.Math.Between(0, auxGrid.right.length - 1);
            elem = auxGrid.right[auxPos];
            img = this.add.image(elem[0], elem[1], 'smallball');
            this.currentImages.push(img);
            auxGrid.right.splice(auxPos, 1); // Delete one element
        }
        this.currentState += 1;
        this.referenceTime = Date.now();
    }

    end()
    {
        this.saveToDB();
        this.accuracy = this.answers.filter(Boolean).length/this.samples;
        if (this.taskConfig.levelType === 'demo') this.accuracy = 1;
        if (this.accuracy < 0.5) {
            this.scene.launch('Instructions', {nextScene: 'MainMenu', level: -1});
        } else {
            // Next level only if it exists
            if (Object.keys(this.levels).indexOf((this.level+1).toString().padStart(2, '0')) !== -1) {
                this.scene.launch('Instructions', {nextScene: this.name, level: this.level + 1});
            } else {
                this.scene.launch('Instructions', {nextScene: 'MainMenu', level: -1});
            }
        }
        this.scene.setActive(false, this.name);
        this.scene.setVisible(false, this.name);
        
        const countdownScene = this.scene.get('Countdown');
        this.scene.setVisible(false, countdownScene);
    }
}

export default BallGame;
