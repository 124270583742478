import Phaser     from 'phaser';
import BinaryGame from './BinaryGame';

/**
 * STROOP: Compare numbers of different sizes in a numerical line range.
 * Show differences between congruent and incongruent samples.
 */
class Stroop extends BinaryGame {

    constructor ()
    {
        super('Stroop');
        this.id = '03';
    }

    generateSamples (range, samples)
    {
        let auxSize = [175, 150];
        this.sizes = [];
        let aux = 0;
        for (let i = 0; i < samples; i++) {
            aux = Phaser.Math.Between(range[0], range[1]);
            this.leftElems.push(aux);
            if (Phaser.Math.Between(0,1) === 1) auxSize.reverse();
            this.sizes.push(auxSize.slice());
            this.rightElems.push(aux);
            while (this.rightElems[i] === this.leftElems[i]) {
                this.rightElems[i] = Phaser.Math.Between(range[0], range[1]);
            }
        }
    }

    isCorrect (side)
    {
        if (side === 'L') {
            this.answers.push(this.sizes[this.currentState-1][0] - this.sizes[this.currentState-1][1] > 0);
        } else if (side === 'R') {
            this.answers.push(this.sizes[this.currentState-1][1] - this.sizes[this.currentState-1][0] > 0);
        } else {
            this.answers.push(false);
        }
    }

    next()
    {
        if (this.currentState >= this.rightElems.length) {
            this.end();
            return;
        }
        // Draw elements
        this.leftText.setText(this.leftElems[this.currentState].toString()).setFontSize(this.sizes[this.currentState][0]);
        this.rightText.setText(this.rightElems[this.currentState].toString()).setFontSize(this.sizes[this.currentState][1]);
        this.currentState += 1;
        this.referenceTime = Date.now();
    }
}

export default Stroop;
