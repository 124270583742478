import React, { Component } from 'react';
import logo from './../logo.svg';
import './Main.css';

class Main extends Component {
  render() {
    return (
      <div className="Main">
        <header className="Main-header">
        <img src={logo} className="Main-logo" alt="logo" />
        <h1 className="Main-title">Bienvenido a Additae</h1>
        </header>
        {/* <p className="Main-intro">
        Additae es un proyecto de investigación con el fin de diagnosticar y tratar la <b>Discalculia</b>.
        </p> */}
      </div>
    );
  }
}

export default Main;
